import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Floater from 'components/floating-grid'
import Layout from 'components/layout'
import Content from 'components/category/content'
import { graphql } from 'gatsby'

const items = [
  {
    link: '/implements/utility-tractors/snow-removal-equipment/front-blades/',
    imageId: 'frontBladesImage',
    name: 'Front Blades',
    alt: 'Front Blades',
  },
  {
    link: '/implements/utility-tractors/snow-removal-equipment/rotary-brooms/',
    imageId: 'rotaryBroomsImage',
    name: 'Rotary Brooms',
    alt: 'Rotary Brooms',
  },
  {
    link: '/implements/utility-tractors/snow-removal-equipment/snow-pushes/',
    imageId: 'snowPushesImage',
    name: 'Snow Pushes',
    alt: 'Snow Pushes',
  },
  {
    link: '/implements/utility-tractors/snow-removal-equipment/snowblowers/',
    imageId: 'snowblowersImage',
    name: 'Snowblowers',
    alt: 'Snowblowers',
  },
]

const SnowRemovalEquipmentPage = ({ data }) => {
  const { heroImage } = data
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>Utility Tractor Implements - Snow Removal Equipment | Hutson Inc</title>
        <meta
          name='description'
          content="Can't find your driveway under a pile of snow? Hook up Frontier snow removal equipment and put snow in its place. Find the full line-up at Hutson."
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Implements',
                'item': 'https://www.hutsoninc.com/implements/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Utility Tractors',
                'item': 'https://www.hutsoninc.com/implements/utility-tractors/',
              },
              {
                '@type': 'ListItem',
                'position': 4,
                'name': 'Snow Removal Equipment',
                'item':
                  'https://www.hutsoninc.com/implements/utility-tractors/snow-removal-equipment/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Snow Removal Equipment' />

      <Content>
        <Floater items={gridItems} />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    heroImage: file(
      relativePath: { eq: "implements/utility-tractors/snow-removal-equipment-header.jpg" }
    ) {
      ...FullWidthImage
    }
    frontBladesImage: file(
      relativePath: { eq: "implements/utility-tractors/snow-removal-equipment/front-blades.jpg" }
    ) {
      ...FloatingGridImage
    }
    rotaryBroomsImage: file(
      relativePath: { eq: "implements/utility-tractors/snow-removal-equipment/rotary-brooms.jpg" }
    ) {
      ...FloatingGridImage
    }
    snowPushesImage: file(
      relativePath: { eq: "implements/utility-tractors/snow-removal-equipment/snow-pushes.jpg" }
    ) {
      ...FloatingGridImage
    }
    snowblowersImage: file(
      relativePath: { eq: "implements/utility-tractors/snow-removal-equipment/snowblowers.jpg" }
    ) {
      ...FloatingGridImage
    }
  }
`

export default SnowRemovalEquipmentPage
